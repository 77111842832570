<template>
  <div class="config-setting style-box-setting">
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter">
          <v-progress-circular
            class="ml-2"
            v-if="getLoadingFamilleConfig"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center"
                v-on="on"
                v-bind="attrs"
                @click.prevent.stop="handleModalFamille('add', 'show')"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter une famille</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="table-gestion-content">
      <v-data-table
        v-model="selectedTable"
        :headers="computedFields"
        :items="getListFamilleConfigFrias"
        :items-per-page="getListFamilleConfigFrias.length"
        class="table-gestion-client-passage"
        hide-default-footer
        disable-sort
        :calculate-widths="false"
        :fixed-header="true"
        hide-action
        :no-data-text="
          getLoadingFamilleConfig
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <v-btn
              v-if="checkPermission('GDFCMUF')"
              small
              class="block-icon-reglement-fac mr-2"
              @click.prevent.stop="handleModalFamille('update', 'show', item)"
              title="Modifier Famille"
            >
              <font-awesome-icon icon="pencil-alt" />
            </v-btn>
            <v-btn
              v-if="checkPermission('GDFCVUF')"
              small
              class="block-icon-reglement-fac mr-2"
              @click.prevent.stop="BlockUnblockConfigclick(item)"
            >
              <font-awesome-icon
                v-if="item.blocked == true"
                title="Débloquer Famille"
                icon="eye-slash"
              />

              <font-awesome-icon
                v-if="item.blocked == false"
                title="Bloquer Famille"
                icon="eye"
              />
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </div>
    <!--MODAL ADD FAMILLE-->
    <v-dialog
      v-model="addFamille"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter Famille </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalFamille('add', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="familleToAdd.name"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Nom Famille est obligatoire.']"
                  class="msg-error"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
          <div v-if="getErrorFamilleConfig" class="message-error-modal">
            <ul v-if="Array.isArray(getErrorFamilleConfig)" class="mb-0">
              <li v-for="(e, index) in getErrorFamilleConfig" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getErrorFamilleConfig }}
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleSubmitFamille"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click.prevent.stop="handleModalFamille('add', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL UPDATE FAMILLE-->
    <v-dialog
      v-model="updateFamille"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card v-if="famillyToUpdate && famillyToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier Famille </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalFamille('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="famillyToUpdate.familly"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Nom Famille est obligatoire.']"
                  class="msg-error"
                  ref="nom"
                  color="#704ad1"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </form>
          <div v-if="getErrorFamilleConfig" class="message-error-modal">
            <ul v-if="Array.isArray(getErrorFamilleConfig)" class="mb-0">
              <li v-for="(e, index) in getErrorFamilleConfig" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getErrorFamilleConfig }}
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleUpdateFamille"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn
            text
            @click.prevent.stop="handleModalFamille('update', 'hide')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      famillyToUpdate: null,
      oldFamilleToUpdate: null,
      familly: null,
      addFamille: false,
      updateFamille: false,
      loading: false,
      selectedTable: [],
      familleToAdd: {
        name: null
      }
    };
  },
  methods: {
    ...mapActions([
      'fetchAllFammilleConfig',
      'BlockUnblockConfigFamilleFraisDync',
      'initErrorsFamillyConfig',
      'updateConfigFamilleFraisDync',
      'addNewFamilleConfigFrais',
    ]),
    async BlockUnblockConfigclick(item) {
      await this.BlockUnblockConfigFamilleFraisDync(item);
    },
    fetchAllFammilleConfigAction() {
      this.fetchAllFammilleConfig({});
    },
    async handleSubmitFamille() {
      this.initErrorsFamillyConfig();
      if (this.$refs.nom.validate()) {
        this.loading = true;
        const response = await this.addNewFamilleConfigFrais(this.familleToAdd);
        if (response) {
          this.loading = false;
          this.handleModalFamille('add', 'hide');
        } else {
          this.loading = false;
        }
      }
    },
    async handleUpdateFamille() {
      this.initErrorsFamillyConfig();
      if (this.$refs.nom.validate()) {
        this.loading = true;
        const response = await this.updateConfigFamilleFraisDync({
          familly: this.oldFamilleToUpdate,
          famillyToUpdate: this.famillyToUpdate
        });
        if (response.success) {
          this.loading = false;
          this.handleModalFamille('update', 'hide');
        } else {
          this.loading = false;
        }
      }
    },
    handleModalFamille(method, action, item) {
      if (method == 'add') {
        if (action == 'show') {
          this.addFamille = true;
        } else {
          this.addFamille = false;
          this.familleToAdd.name = null;
          this.$refs.nom.reset();
        }
      }

      if (method == 'update') {
        if (action == 'show') {
          this.oldFamilleToUpdate = item;
          this.famillyToUpdate = { ...item };
          this.updateFamille = true;
        } else {
          this.updateFamille = false;
          this.famillyToUpdate = null;
          this.oldFamilleToUpdate = null;
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getListFamilleConfigFrias',
      'getErrorFamilleConfig',
      'getLoadingFamilleConfig',
      'getErrorFamilleConfig',
      'checkPermission'
    ]),
    computedFields() {
      let fields = [
        {
          value: 'familly',
          text: 'Famille',
          show: true,
          sortable: false
        },
        { value: 'Actions', text: 'Actions', show: true }
      ];

      return fields.filter(field => field.show == true);
    }
  },
  mounted() {
    this.fetchAllFammilleConfigAction();
  }
};
</script>
<style lang="scss" scoped>
.config-setting {
  padding-top: 0px;
  overflow: hidden;
  width: 100%;

  .box-content {
    padding-top: 0px !important;
  }
}
</style>
